import React, { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { extractAddress } from '../helper'
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui'
import { useWallet } from '@solana/wallet-adapter-react';
import SolanaWalletInfo from '../WalletInfo/SolanaWalletInfo'

export default function SolanaWalletConfig({
  amount,
  allBalance,
  selectedChain,
  selectedToken,
  setAddress,
}) {

  const { wallet, connected, connect, disconnect, publicKey } = useWallet();

  useEffect(() => {
    if (publicKey && publicKey.toBase58()) {
      setAddress(publicKey.toBase58());
    }
  }, [publicKey])

  return (
    <div>
      <div className="title-block">
        <strong >
          {
            publicKey ? `Wallet address ${extractAddress(publicKey.toBase58())}` : " Connect your wallet"
          }
        </strong>
      </div>
      <div className='mt-3'>
        <div className="p-3 text-dark" style={{ borderRadius: "10px" }}>
          {
            !connected ?
              <div className='solana-connect-button'>
                <p className='mb-4 wallet-info-text'>
                  You have selected the Solana network. Now, connect your Solana wallet to proceed. We support wallets integrated into the Solana ecosystem, providing secure and fast transactions.
                  <br />To proceed press the <b>Connect Your Solana Wallet</b> button below.
                </p>
                <WalletMultiButton>Connect Your Solana Wallet</WalletMultiButton>
              </div> :
              <div>
                <SolanaWalletInfo
                  amount={amount}
                  allBalance={allBalance}
                  selectedChain={selectedChain}
                  selectedToken={selectedToken}
                />
                <hr />
                <div className="d-flex justify-content-end">
                  {/* <WalletDisconnectButton>Disconnect Wallet</WalletDisconnectButton> */}
                  <Button variant="danger" onClick={() => {
                    disconnect();
                    setAddress("");
                  }}>
                    Disconnect Wallet
                  </Button>
                </div>
              </div>
          }
        </div>
      </div>
    </div>
  )
}
