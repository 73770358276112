import React, { memo, useContext, useState } from 'react';
import cryllexSvg from "./../../../../assets/images/crillex.svg"
import { Button } from 'react-bootstrap';

const StellarPayBtn = memo(({
    price,
    paymentData,
    isTransactionValidated,
    pay,
    selectedToken,
    amount,
    paySuccess,
    payError,
    selectedChain,
    to,
    isLoadingPay
}) => {

    const [account, setAccount] = useState("");


    const _sendTransaction = () => {
        console.log("_sendTransaction");
    }

    return paymentData ? <div className='h-100 d-flex justify-content-center align-items-center'>
        <Button
            type='button'
            variant='primary'
            size='lg'
            className='w-100'
            style={{ maxWidth: "400px", fontWeight: "500" }}
            disabled={isLoadingPay || !isTransactionValidated || !account ? true : false}
            onClick={() => {
                if (isTransactionValidated && account && !isLoadingPay) {
                    pay(_sendTransaction);
                }
            }}
        >
            <div className='d-flex justify-content-between'>
                <div className='d-flex align-items-center gap-2'>
                    <img src={cryllexSvg} alt="/" />
                    {
                        isLoadingPay ? "Loading..." : "Pay"
                    }

                </div>
                <div>
                    {
                        price ?
                            <span className="ms-2"><b>{price} {selectedToken?.slug}</b></span>
                            : null
                    }
                </div>
            </div>
        </Button>
    </div> : null
})

export default StellarPayBtn;

