import React, { useContext, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { extractAddress } from '../helper'
import TronWalletInfo from '../WalletInfo/TronWalletInfo';
import TronContext from '../../../../TronContext';

export default function TronWalletConfig({
  amount,
  allBalance,
  selectedChain,
  selectedToken,
  setAddress,
}) {

  const { account, connectWallet, disconnectWallet } = useContext(TronContext);

  useEffect(() => {
    if (account) {
      setAddress(account);
    }
  }, [account])

  return (
    <div>
      <div className="title-block">
        <strong >
          {
            account ? `Wallet address ${extractAddress(account)}` : " Connect your wallet"
          }
        </strong>
      </div>
      <div className='mt-3'>
        <div className="p-3 text-dark">
          {
            !account ?
              <div>
                <p className='mb-4 wallet-info-text'>
                  You have selected the Tron network. To continue, connect your wallet using TronLink or another compatible solution. This method ensures secure interaction with the Tron network and allows you to send transactions directly from your wallet.
                  <br />To proceed press the <b>Connect Your Tron Wallet</b> button below.
                </p>
                <Button variant="primary w-100 shadow" onClick={connectWallet}>Connect Your Tron Wallet</Button>
              </div> :
              <div>
                <TronWalletInfo
                  amount={amount}
                  allBalance={allBalance}
                  selectedChain={selectedChain}
                  selectedToken={selectedToken}
                />
                <hr />
                <div className="d-flex justify-content-end">
                  <Button variant="danger" onClick={() => {
                    disconnectWallet();
                    setAddress("");
                  }}
                  >
                    Disconnect Wallet
                  </Button>
                </div>
              </div>
          }
        </div>
      </div>
    </div>
  )
}


