import React from 'react'
import { Button } from 'react-bootstrap'
import { extractAddress } from '../helper'
import StellarWalletInfo from '../WalletInfo/StellarWalletInfo'

export default function StellarWalletConfig({
  address = "",
  allBalance,
  setAddress,
  selectedToken,
  selectedChain,
  amount,
  _disconnect
}) {

  return (
    <div>
      <div className="title-block">
        <strong >
          {
            address ? `Wallet address ${extractAddress(address)}` : " Connect your wallet"
          }
        </strong>
      </div>
      <div className='mt-3'>
        <div className="p-3 text-dark" style={{ borderRadius: "10px" }}>
          {
            !address ?
              <div>
                <p className='mb-4'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                <Button variant="primary w-100 shadow">Connect Your Stellar Wallet</Button>
              </div> :
              <div>
                <StellarWalletInfo
                  amount={amount}
                  allBalance={allBalance}
                  selectedChain={selectedChain}
                  selectedToken={selectedToken}
                />
                <hr />
                <div className="d-flex justify-content-end">
                  <Button variant="danger" onClick={() => {
                    _disconnect();
                    setAddress("");
                  }}>
                    Disconnect Wallet
                  </Button>
                </div>
              </div>
          }
        </div>
      </div>
    </div>
  )
}
