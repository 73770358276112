import React from 'react'
import { STELLAR_PLATFORM_TYPE } from '../../../../Constants/MainKeys';

export default function NetworksAndCoins({
  chains,
  selectedChain,
  selectedToken,
  onSetNetwork,
  onSetCrypto,
  isShowNetworkLoading,
  isShowTokenLoading,
  setActiveTab,
}) {

  return (<div>
    <div className="mb-3 title-block">
      <strong >Select Network & Token</strong>
    </div>

    <div className="p-3 text-dark">
      <div>{
        chains && chains.length ?
          <div className={`${isShowNetworkLoading || isShowTokenLoading ? "disabled" : ""}`}>
            <div>
              <p><b className='text-muted'>Networks</b></p>
              <ul className="cx-accordion-body-list flex-wrap" id="crypto-block">
                {
                  chains.map(item => {
                    return <li
                      key={item.id}
                      title={item.chainName}
                      style={{ cursor: "pointer" }}
                      className={`cx-accordion-list-item px-3 pt-3 mb-3 ${selectedChain && selectedChain.id === item.id ? "selected-item" : ""} ${item.platformType === STELLAR_PLATFORM_TYPE ? "disabled" : ""}`}
                      onClick={() => {
                        onSetNetwork(item, setActiveTab);
                      }}
                    >
                      <img src={item.chainLogo} alt="logo" className='rounded' />
                      <div className="cx-accordion-list-item-name">
                        {item.chainSlug}
                      </div>
                    </li>
                  })
                }
              </ul>

            </div>
            {
              selectedChain && selectedChain.tokens && selectedChain.tokens.length ?
                <div>
                  <hr />
                  <p><b className='text-muted'>Tokens</b></p>
                  <ul className="cx-accordion-body-list" id="crypto-block">
                    {
                      selectedChain.tokens.map(item => {
                        return <li
                          title={item.name}
                          style={{ cursor: "pointer" }}
                          key={item.id}
                          className={`cx-accordion-list-item pt-3 ${selectedToken && selectedToken.id === item.id ? "selected-item" : ""}`}
                          // onClick={() => setSelectedToken(item)}
                          onClick={() => onSetCrypto(item, setActiveTab)}
                        >
                          <img src={item.logo} alt="logo" className='rounded' />
                          <div className="cx-accordion-list-item-name" >
                            {item?.slug}
                          </div>
                        </li>
                      })
                    }
                  </ul>
                </div>
                : null
            }
          </div>
          : null
      }</div>
    </div>
  </div>



  )
}
