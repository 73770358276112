import React from 'react';
import { RouterProvider } from "react-router-dom"
import { router } from "./router";
import ErrorModal from './Components/ErrorModal/ErrorModal';


const App = () => {
  return (
    <div>
      <ErrorModal />
      <div className="app-wrapper">
        <RouterProvider router={router()} />
      </div>
    </div>
  );
};

export default App;
