import React, { memo, useCallback } from 'react';
import { parseUnits } from 'viem';
import cryllexSvg from "./../../../../assets/images/crillex.svg"
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { PublicKey, Transaction, Connection } from '@solana/web3.js';
import { createTransferInstruction, getOrCreateAssociatedTokenAccount, TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { useSolana } from '../../../../SolanaContext';
import { Button } from 'react-bootstrap';

const SolanaPayBtn = memo(({
    price,
    paymentData,
    isTransactionValidated,
    pay,
    selectedToken,
    paySuccess,
    payError,
    selectedChain,
    isLoadingPay
}) => {

    const endpoint = useSolana();
    const { publicKey, sendTransaction, disconnect, connected } = useWallet();
    const connection = new Connection(endpoint, 'confirmed');


    // const _sendTransaction = useCallback(async () => {
    //     if (!publicKey) throw new WalletNotConnectedError();
    //     const toPublicKey = new PublicKey(to);
    //     const mintPublicKey = new PublicKey(selectedToken.contractAddress);
    //     const parsedAmount = parseUnits(amount, selectedToken.decimals);
    //     try {
    //         const fromTokenAccount = await getOrCreateAssociatedTokenAccount(
    //             connection,
    //             publicKey,
    //             mintPublicKey,
    //             publicKey
    //         );

    //         const toTokenAccount = await getOrCreateAssociatedTokenAccount(
    //             connection,
    //             toPublicKey,
    //             mintPublicKey,
    //             toPublicKey
    //         );

    //         const transaction = new Transaction().add(
    //             createTransferInstruction(
    //                 fromTokenAccount.address,  // адрес аккаунта отправителя
    //                 toTokenAccount.address,    // адрес аккаунта получателя
    //                 publicKey,                 // публичный ключ отправителя
    //                 // +amount * 1000000,                 // количество токенов для перевода (1 USDT, 6 знаков после запятой)
    //                 parsedAmount,
    //                 [],
    //                 TOKEN_PROGRAM_ID,
    //             )
    //         );
    //         const txHash = await sendTransaction(transaction, connection);
    //         console.log('Transaction hash:', txHash);
    //         paySuccess(txHash, selectedChain.id);
    //     } catch (error) {
    //         payError(error.name, error.message)
    //     }

    // }, [publicKey, sendTransaction, endpoint, to, amount]);
    const _sendTransaction = async (to, amount) => {
        if (!publicKey) throw new WalletNotConnectedError();
        const toPublicKey = new PublicKey(to);
        const mintPublicKey = new PublicKey(selectedToken.contractAddress);
        const parsedAmount = parseUnits(amount, selectedToken.decimals);
        try {
            const fromTokenAccount = await getOrCreateAssociatedTokenAccount(
                connection,
                publicKey,
                mintPublicKey,
                publicKey
            );

            const toTokenAccount = await getOrCreateAssociatedTokenAccount(
                connection,
                toPublicKey,
                mintPublicKey,
                toPublicKey
            );

            const transaction = new Transaction().add(
                createTransferInstruction(
                    fromTokenAccount.address,  // адрес аккаунта отправителя
                    toTokenAccount.address,    // адрес аккаунта получателя
                    publicKey,                 // публичный ключ отправителя
                    // +amount * 1000000,                 // количество токенов для перевода (1 USDT, 6 знаков после запятой)
                    parsedAmount,
                    [],
                    TOKEN_PROGRAM_ID,
                )
            );
            const txHash = await sendTransaction(transaction, connection);
            console.log('Transaction hash:', txHash);
            paySuccess(txHash, selectedChain.id);
        } catch (error) {
            payError(error.name, error.message)
        }

    };

    return paymentData ? <div className='h-100 d-flex justify-content-center align-items-center'>
        <Button
            type='button'
            variant='primary'
            size='lg'
            className='w-100'
            style={{ maxWidth: "400px", fontWeight: "500" }}
            disabled={isLoadingPay || !isTransactionValidated || !connected ? true : false}
            onClick={() => {
                if (isTransactionValidated && connected && !isLoadingPay) {
                    pay(_sendTransaction);
                }
            }}
        >
            <div className='d-flex justify-content-between'>
                <div className='d-flex align-items-center gap-2'>
                    <img src={cryllexSvg} alt="/" />
                    {
                        isLoadingPay ? "Loading..." : "Pay"
                    }

                </div>
                <div>
                    {
                        price ?
                            <span className="ms-2"><b>{price} {selectedToken?.slug}</b></span>
                            : null
                    }
                </div>
            </div>
        </Button>
    </div> : null
})

export default SolanaPayBtn;

