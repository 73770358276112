import React, { memo, useContext } from 'react';
import cryllexSvg from "./../../../../assets/images/crillex.svg"
import TronContext from '../../../../TronContext';
import { Button } from 'react-bootstrap';

const TronPayBtn = memo(({
    price,
    paymentData,
    isTransactionValidated,
    pay,
    selectedToken,
    paySuccess,
    payError,
    selectedChain,
    isLoadingPay,
}) => {

    const { sendTransaction, account } = useContext(TronContext);

    const _sendTransaction = (to = "", amount = 0) => {
        sendTransaction(to, amount, selectedToken).then((txHash) => {
            console.log('Transaction hash:', txHash);
            paySuccess(txHash, selectedChain.id)
        }).catch(error => {
            payError(error?.name, error?.message)
        })
    }

    return paymentData ? <div className='h-100 d-flex justify-content-center align-items-center'>
        <Button
            type='button'
            variant='primary'
            size='lg'
            className='w-100'
            style={{ maxWidth: "400px", fontWeight: "500" }}
            disabled={isLoadingPay || !isTransactionValidated || !account ? true : false}
            onClick={() => {
                if (isTransactionValidated && account && !isLoadingPay) {
                    pay(_sendTransaction);
                }
            }}
        >
            <div className='d-flex justify-content-between'>
                <div className='d-flex align-items-center gap-2'>
                    <img src={cryllexSvg} alt="/" />
                    {
                        isLoadingPay ? "Loading..." : "Pay"
                    }

                </div>
                <div>
                    {
                        price ?
                            <span className="ms-2"><b>{price} {selectedToken?.slug}</b></span>
                            : null
                    }
                </div>
            </div>
        </Button>
    </div> : null
})

export default TronPayBtn;

