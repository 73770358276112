import { createSlice } from "@reduxjs/toolkit";

const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: isJson(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")) : null,
    userToken: localStorage.getItem("userToken") ? localStorage.getItem("userToken") : "",
    refreshToken: localStorage.getItem("refreshToken"),
    isShowAuthModal: false,
    cancelUrl: localStorage.getItem("cancelUrl") ? localStorage.getItem("cancelUrl") : "",
  },
  reducers: {
    setUserData: (state, { payload }) => {
      if (payload) {
        localStorage.setItem("user", JSON.stringify(payload));
      } else {
        localStorage.removeItem("user")
      }
      state.user = payload;
    },
    setUserToken: (state, { payload }) => {
      if (payload) {
        localStorage.setItem("userToken", payload?.accessToken);
        localStorage.setItem("refreshToken", payload?.refreshToken);
      } else {
        localStorage.removeItem("userToken");
        localStorage.removeItem("refreshToken");
      }
      state.userToken = payload?.accessToken || "";
      state.refreshToken = payload?.refreshToken || "";
    },
    setIsShowAuthModal: (state, { payload }) => {
      state.isShowAuthModal = payload;
    },
    setCancelUrl: (state, { payload }) => {
      if (payload) {
        localStorage.setItem("cancelUrl", payload);
      } else {
        localStorage.removeItem("cancelUrl", payload);
      }
      state.cancelUrl = payload;
    },
  },
});

export const {
  setUserData,
  setUserToken,
  setIsShowAuthModal,
  setCancelUrl
} = userSlice.actions;

export default userSlice.reducer;
