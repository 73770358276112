import React, { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { useAccount } from 'wagmi';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import WalletConnectWalletInfo from '../WalletInfo/WalletConnectWalletInfo';
import { extractAddress } from '../helper';
import { disconnect } from '@wagmi/core';
import { useWagmiConfig } from '../../../../WalletConnectContext';

export default function WalletConnectWalletConfig({
  allBalance,
  selectedChain,
  selectedToken,
  setAddress,
}) {

  const config = useWagmiConfig();
  const { open: openModal, close: closeModal, } = useWeb3Modal()
  const { address, isConnected } = useAccount();

  useEffect(() => {
    if (address) {
      setAddress(address);
    }
  }, [address])

  const _disconnect = async () => {
    await disconnect(config);
    setAddress("");
  }

  return (
    <div>
      <div className="title-block">
        <strong >
          {
            address ? `Wallet address ${extractAddress(address)}` : " Connect your wallet"
          }
        </strong>
      </div>
      <div className='mt-3'>
        <div className="p-3 text-dark">
          {
            !address ?
              <div>
                <p className='mb-4 wallet-info-text'>
                  You have selected a network and token. Now, connect your wallet using a secure connection via WalletConnect. This method supports both mobile and desktop wallets, allowing you to quickly interact with the selected network.
                  <br />To proceed press the <b>Connect Wallet</b> button below.
                </p>
                <Button variant="primary w-100 shadow" onClick={() => openModal()}>Connect Wallet</Button>
              </div> :
              <div>
                {/* <w3m-button balance loadingLabel /> */}
                <WalletConnectWalletInfo
                  allBalance={allBalance}
                  selectedChain={selectedChain}
                  selectedToken={selectedToken}
                />
                <div className="d-flex justify-content-end mt-3">
                  <Button size='sm' variant="danger" onClick={_disconnect}>Disconnect Wallet</Button>
                </div>
              </div>
          }
        </div>
      </div>
    </div>
  )
}
