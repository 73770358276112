import axios from 'axios';
import store from "./../Store/index.js"
import AlertService from './AlertService.js';
import { setUserData, setUserToken } from '../Store/Reducers/userReducer.js';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL_KEY || "",
  withCredentials: true,
});
const cryptoApi = axios.create({
  baseURL: process.env.REACT_APP_CRYPTO_API_URL_KEY || "",
  withCredentials: true,
});

const blackListApi = axios.create({
  baseURL: process.env.REACT_APP_BLACKLIST_API_URL_KEY || "",
});

const veriffApi = axios.create({
  baseURL: process.env.REACT_APP_VERIFF_API_URL_KEY || "",
  withCredentials: true,
});

const identityApi = axios.create({
  baseURL: process.env.REACT_APP_IDENTITY_API_URL_KEY || "",
});

api.interceptors.request.use(async config => {
  config.headers = {
    Authorization: localStorage.getItem("userToken")
      ? `Bearer ${localStorage.getItem("userToken")}`
      : "",
    token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
    // 'Content-Type': 'application/json; charset=utf-8;',
    language: "en",
  };

  return config;
});

api.interceptors.response.use(response => {
  if (response.data) return response.data;
}, error => {
  if (error.response) {
    if (error.response.status === 404) { // Status Code: 404
      return Promise.reject("404 Not Found");
    } else if (error.response.status === 401) {
      return Promise.reject("401 Unauthorized");
      const refreshToken = localStorage.getItem("refreshToken");
      const user = localStorage.getItem("user");
      const token = localStorage.getItem("userToken");
      if (!refreshToken || !user || !token) {
        logout();
      } else {
        getUserDataByRefreshToken(refreshToken);
      }
      return false;
    } else if (error.response.status === 500) { // Status Code: 500
      return Promise.reject("500 Internal Server Error");
    }
    if (typeof error.response.data === "object") {
      return Promise.reject(error.response.data);
    } else {
      return Promise.reject("Unrecognized error");
    }
  } else {
    return Promise.reject("Unrecognized error");
  }
});

const logout = () => {
  store.dispatch(setUserData(null));
  store.dispatch(setUserToken(null));
}

const getUserDataByRefreshToken = (refreshToken) => {
  ApiService.getUserDataByRefreshToken(refreshToken).then(response => {
    if (response && response.data && response.data.data && response.data.data.accessToken) {
      const data = { ...response.data.data };
      store.dispatch(setUserToken(data));
    } else {
      logout();
    }
  }).catch(() => logout());
}

class ApiService {
  static login(data) {
    return identityApi.post(`/account/Login`, data)
  };
  static getUserDataByRefreshToken(data) {
    return identityApi.get(`/account/refreshToken?refreshToken=${data}`);
  }
  static validateKyc(data) {
    return api.post(`/kyc/check`, data)
  };
  static verifyIsKycAlreadyDone() {
    return api.get(`/kyc-info`)
  };
  static getWallets() {
    return api.get(`/api/wallet/list`)
  };
  static sendKycData(data) {
    return api.post(`/kyc`, data)
  };
  static onSetCrypto(data) {
    return api.post(`/set-crypto`, data)
  };
  static onSetNetwork(data) {
    return api.post(`/set-network`, data)
  };
  static selectWallet(data) {
    return api.post(`/set-wallet`, data)
  };
  static getPaymentData(token) {
    if (!token) { return false; }
    return api.get(`/get/${token}`)
  };
  static loadOrgData(organizationId) {
    return api.get(`/api/organization/paymentOrg/?orgId=${organizationId}`)
  };
  static pay() {
    return api.post(`/pay`)
  };
  static cancelPayment(token) {
    return api.post(`/cancel/${token}`)
  };
  static qrpay() {
    return api.get(`/qrpay`)
  };
  static paySuccess(data) {
    return api.post(`/hash`, data)
  };
  static payError(data) {
    return api.post(`/pay-error`, data)
  };
  static getCountries() {
    return api.get(`/api/Country/GetAll`)
  };

  //User
  static googleLogin(data) {
    return api.post(`/api/user/googleAuth`, data);
  }
  static getCurrentUser() {
    return api.get(`/api/user/current`);
  }
  static userRegistration(data) {
    return api.post(`/api/User/CreateCustomer`, data);
  }
  static userForgotPassword(email) {
    return api.post(`/api/User/GetRestorePasswordUrl?email=${email}`);
  }
  static userRestorePassword(data) {
    return api.post(`/api/User/RestorePassword`, data);
  }
  static getGenders() {
    return api.get(`/api/User/GetGenders`);
  }
  static getDocumentTypes() {
    return api.get(`/api/UserDocument/GetDocumentTypes`);
  }
  static updatePaymentCustomerData(data) {
    return api.put(`/api/User/PaymentCustomerUpdate`, data);
  }

  //BlackList
  static checkBlackList(item) {
    return blackListApi.post(`/api/BlackList/Check?val=${item}`)
  };
  static checkBlackListByHash(item) {
    return blackListApi.post(`/api/BlackList/CheckByHash?hash=${item}`)
  };
  static checkBlackListByIp() {
    return blackListApi.post(`/api/BlackList/CheckByIP`)
  };
  static checkBlackListByCountry(item) {
    return blackListApi.post(`/api/BlackList/CheckByCountry?country=${item}`)
  };
  static checkBlackListByCity(item) {
    return blackListApi.post(`/api/BlackList/CheckByCity?country=${item}`)
  };


  //Chain
  static getChains() {
    return api.get(`/api/chainConfig/getAll`)
  };

  // Veriff
  static startVeriff(data) {
    return veriffApi.post(`/api/Veriff/Start`, data);
  }
  static checkVeriff(sessionId) {
    return veriffApi.get(`/api/Veriff/Check/${sessionId}`);
  }
  static fileUploadVeriff(data) {
    return veriffApi.post(`/api/Veriff/Upload`, data);
  }

  static getAccountBalance(address, chinId) {
    return cryptoApi.post(`/scanner/getAddressBalance?address=${address}&chinId=${chinId}`)
  };
}

export default ApiService;
