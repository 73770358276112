export const AVALANCHE_PLATFORM_TYPE = 0;
export const ETHEREUM_PLATFORM_TYPE = 1;
export const SOLANA_PLATFORM_TYPE = 2;
export const STELLAR_PLATFORM_TYPE = 3;
export const TRON_PLATFORM_TYPE = 4;
export const ALL_VALID_IMAGE_TYPES = ["png", "jpg", "jpeg"];


export const DOCUMENT_TYPES = [
  {
    id: "PASSPORT",
    name: "Passport"
  },
  {
    id: "ID_CARD",
    name: "ID Card"
  },
  {
    id: "DRIVERS_LICENSE",
    name: "Driver License"
  },
  {
    id: "RESIDENCE_PERMIT",
    name: "Residence Permit"
  },
  {
    id: "VISA",
    name: "Visa"
  },
]

export const GENDERS = [
  {
    id: "M",
    name: "Male"
  },
  {
    id: "F",
    name: "Female"
  },
]

export const ERROR_CODES = {
  InvalidMerchant: 10,
  InvalidCurrency: 20,
  InvalidAmount: 30,
  ErrorAuthenticate: 40,
  InvalidToken: 50,
  InvalidPhone: 60,
  KYCAlreadyValidated: 70,
  InvalidAgent: 80,
  UnAgentOrganization: 90,
  TransactionNumberInvalid: 100,
  TransactionNumberDuplicate: 101,
  TransactionNumberEmpty: 102,
  TransactionError: 103,
  TransactionTimeout: 104,
  TransactionAddressInvalid: 105,
  TransactionLocked: 106,
  TransactionSetCrypto: 107,
  UnAuthorizedRequest: 110,
  CacheAllocationFailed: 500,
  CacheLockFailed: 510,
  GeneralError: 999,
  UnknownError: 1000,
  Alert: 1001
}