import React, { useState, useEffect } from 'react'
import { Button, Container, Form, Modal } from 'react-bootstrap'
import UserVeriffForm from './components/UserVeriffForm';
import ApiService from '../../Services/ApiService';
import { useDispatch, useSelector } from 'react-redux';
import AlertService from '../../Services/AlertService';
import { setIsShowVeriffModal } from '../../Store/Reducers/mainReducer';
import { IoShieldCheckmarkSharp } from "react-icons/io5";
import { IoCloseSharp } from "react-icons/io5";
import { v4 as uuidv4 } from 'uuid';
import { ImSpinner3 } from 'react-icons/im';

export default function VeriffModal({ countries, paymentData }) {

  const dispatch = useDispatch();
  const { token } = useSelector(state => state.main);
  const { isShowBlackListModal } = useSelector(state => state.main);
  const { user } = useSelector(state => state.user);
  const { isShowVeriffModal } = useSelector(state => state.main);
  const [sessionId, setSessionId] = useState(null);
  const [kycStatus, setKycStatus] = useState("pending");
  const [isShowLoader, setIsShowLoader] = useState(false);
  const [isShowSuccess, setIsShowSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [isAgree, setIsAgree] = useState(false);

  // useEffect(() => {
  //   if (user) {
  //     getPaymentData();
  //   }
  // }, [user, token])

  useEffect(() => {
    return () => {
      onClose();
    }
  }, [user])

  useEffect(() => {
    let interval;
    if (isShowLoader && sessionId) {
      interval = setInterval(() => {
        getPaymentData();
      }, 3000);
    }
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [isShowLoader, sessionId]);

  useEffect(() => {
    if (paymentData) {
      if (paymentData.requestKyc && paymentData.kycStatus !== "approved") {
        dispatch(setIsShowVeriffModal(true))
      }
    }
  }, [paymentData])

  const getPaymentData = () => {
    if (!token) { return false; }
    ApiService.getPaymentData(token).then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        if (data.kycStatus) {
          setKycStatus(data.kycStatus);
        }
        if (!data.requestKyc && data.kycStatus === "approved") {
          setIsShowSuccess(true);
          setIsShowLoader(false);
          return false;
        }
        if (data.requestKyc && data.kycStatus !== "approved") {
          dispatch(setIsShowVeriffModal(true))
        }
        // else {
        //   dispatch(setIsShowVeriffModal(false))
        //   setSessionId(null);
        //   setIsShowLoader(false);
        // }
      };
    }).catch(error => getFail(error))
  }

  const getFail = (error) => {
    error && AlertService.alert("error", error);
  }

  const setStatusText = () => {
    if (!kycStatus) { return "" }
    switch (kycStatus) {
      case "pending":
        return <p>Current status: pending. We will notify you as soon as the verification is complete. If any additional information is required, we will inform you promptly.</p>
      case "approved":
        return <p>Your KYC verification has been approved! Thank you for completing the process. You can now proceed with all available features on the platform.</p>
      case "resubmission_requested":
        return <p>Your KYC verification requires resubmission. Please review the details in your dashboard and provide the necessary information to complete the process.</p>
      case "declined":
        return <p>Your KYC verification has been declined. Please review the reason in your dashboard and feel free to contact support if you need assistance.</p>
      case "expired":
        return <p>Your KYC verification has expired. Please start a new verification process to continue using the platform.</p>
      case "abandoned":
        return <p>Your KYC verification was abandoned. Please complete the process in your dashboard to continue with the verification.</p>
      case "review":
        return <p>Your KYC verification is under review. We are currently processing your information and will notify you once the review is complete.</p>
      default:
        break;
    }
  }

  const onClose = () => {
    dispatch(setIsShowVeriffModal(false))
    setSessionId(null);
    setIsShowLoader(false);
    setIsShowSuccess(false);
  }

  const onStartVeriff = () => {
    ApiService.startVeriff({ requestId: uuidv4(), endUserId: user.id }).then(response => {
      if (response && response.data && response.data.data && response.data.data.verification) {
        const { verification } = response.data.data;
        setSessionId(verification?.id);
        setIsShowLoader(true);
        const url = verification?.url;
        window.open(url, "_blank");
      }
    })
      .catch(error => { AlertService.alert("error", error) })
      .finally(() => {
        setIsLoading(false);
      });
  }

  if (isShowBlackListModal || !paymentData) { return null; }


  return (
    <div>
      <Modal
        show={isShowVeriffModal}
        onHide={() => {
          if (isShowSuccess) {
            onClose()
          }
        }}
        centered
        scrollable
        size='lg'
      >
        {
          !isShowLoader && !isShowSuccess ?
            <Modal.Header closeButton={isShowSuccess ? true : false}>
              <h5>KYC Verification Required</h5>
            </Modal.Header>
            : null
        }
        <Modal.Body className='position-relative' style={{ minHeight: isShowLoader || isShowSuccess ? "300px" : "" }}>
          {
            !isShowLoader && !isShowSuccess ? <Container>
              <div>
                <p>To continue using our platform, you need to complete the <b>KYC (Know Your Customer)</b> verification process.</p>
                <p>To proceed, please check the <b>I Agree</b> checkbox and click the <b>Continue</b> button.</p>
              </div>
              <div>
                <Form.Group controlId="isAgree" className='d-flex align-items-center gap-2'>
                  <Form.Check
                    id="isAgree"
                    type="checkbox"
                    value={isAgree}
                    onChange={(event => setIsAgree(event.target.checked))}
                  />
                  <Form.Label className='mb-0'>
                    <b>I Agree</b>
                  </Form.Label>
                </Form.Group>
              </div>

            </Container> : isShowLoader ?
              <div className='veriff-wait-block'>
                <div className='d-flex justify-content-center align-items-center gap-3 flex-wrap'>
                  <h2 style={{ color: "#212529", fontSize: "40px" }}>
                    Please wait
                  </h2>
                  <div className="veriff-loader"></div>
                </div>
                <p className='mt-3'>Your KYC verification is in progress. Please complete the process in Veriff, ensuring all required steps are followed. You can track your status in your dashboard.</p>
                <div>{setStatusText()}</div>
              </div>
              : isShowSuccess ?
                <div className='veriff-wait-block'>
                  <div className='d-flex justify-content-center align-items-center gap-3 flex-column'>
                    <IoShieldCheckmarkSharp size={80} color='#0533b3' />
                    <h2 className='text-center' style={{ color: "#0533b3", fontSize: "40px" }}>
                      The verification was successful.
                    </h2>
                  </div>
                </div>
                : null
          }
          {/* <Container
            className={isShowLoader || isShowSuccess ? "veriff-disabled" : ""}
          > */}
          {/* <FileUploadComponent /> */}
          {/* <UserVeriffForm countries={countries} setIsShowLoader={setIsShowLoader} setSessionId={setSessionId} /> */}


          {/* </Container> */}
        </Modal.Body>
        {
          isShowLoader || isShowSuccess ? null :
            <Modal.Footer>
              <div>
                {
                  isLoading ?
                    <Button type='submit' variant="primary" className='px-4' disabled>
                      <span className='me-2'> Loading</span>
                      <ImSpinner3 className='rotating' />
                    </Button>
                    : <Button variant="primary" type="submit" disabled={!isAgree} onClick={onStartVeriff}> Continue</Button>
                }
              </div>
            </Modal.Footer>
        }
      </Modal>
    </div>
  )
}