import React from 'react';
import { extractAddress } from '../helper';

const StellarWalletInfo = ({ selectedChain, selectedToken, allBalance, connected, publicKey }) => {

  return (
    <>
      {
        connected && <div className="info-block">
          <div className="text-muted text-nowrap">Status</div>
          <div className="dotes w-100" />
          <div className="text-success capitalize">Connected</div>
        </div>
      }
      {
        publicKey && <div className="info-block mt-2">
          <div className="text-muted text-nowrap">Address</div>
          <div className="dotes w-100" />
          <div className="text-muted capitalize">{extractAddress(publicKey.toBase58())}</div>
        </div>
      }
      {
        selectedChain && <div className="info-block mt-2">
          <div className="text-muted text-nowrap">Network</div>
          <div className="dotes w-100" />
          <div className="text-muted text-nowrap">
            {
              selectedChain ?
                <div className="d-flex gap-2 align-items-center">
                  <span>{selectedChain.chainName}</span>
                  <img src={selectedChain.chainLogo} width={20} className="rounded" />
                </div>
                : "Not set"
            }
          </div>
        </div>
      }
      {
        allBalance && <div className="info-block mt-2">
          <div className="text-muted text-nowrap">Amount</div>
          <div className="dotes w-100" />
          <div className="text-muted text-nowrap">
            {
              selectedToken ?
                <div className="d-flex gap-2 align-items-center">
                  <span>{allBalance?.balance}</span>
                  <img src={allBalance.currencyLogo} width={20} className="rounded" />
                </div>
                : "Not set"
            }
          </div>
        </div>
      }
      {/* {connected && <p className='mb-1 capitalize'><b>Status: </b><span className='text-success' style={{ fontWeight: "500" }}>Connected</span></p>}
      {publicKey && <p className='word-break-break-word mb-1'><b>Address: </b><span style={{ fontWeight: "500" }}>{publicKey.toBase58()}</span></p>}
      {selectedChain && <p className='word-break-break-word mb-1 d-flex gap-1 align-items-center'><b>Network: </b> <span style={{ fontWeight: "500" }}>{selectedChain.chainName} </span> <img className='rounded' width={20} src={selectedChain.chainLogo} alt="/" /></p>}
      {selectedToken && <p className='word-break-break-word mb-1 d-flex gap-1 align-items-center'><b>Amount: </b><span style={{ fontWeight: "500" }}>{allBalance?.balance}</span> <img className='rounded' width={20} src={selectedChain.chainLogo} alt="/" /></p>} */}
    </>

  );
}

export default StellarWalletInfo
