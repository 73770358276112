import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import warningSvg from "../assets/icons/warning.svg";
import { toast } from 'react-toastify';
import store from "./../Store/index"
import { setIsShowTransactionExpiredModal } from '../Store/Reducers/mainReducer';
import { setErrorModal } from "../Store/Reducers/errorReducer";

const getAlertType = (type, cb, error) => {
  if (!type) { cb.error(error) };
  switch (type) {
    case 'info':
      return cb.info(error, { position: toast.POSITION.BOTTOM_LEFT });
    case 'success':
      return cb.success(error, { position: toast.POSITION.BOTTOM_LEFT });
    case 'warning':
      return cb.warning(error, { position: toast.POSITION.BOTTOM_LEFT });
    case 'error':
      return cb.error(error, { position: toast.POSITION.BOTTOM_LEFT });
    default:
      break;
  }
}

export default class AlertService {

  // static alert = (type, error) => {
  //   if (typeof error === "object") {
  //     if (error.message || error.respmess) {
  //       const respMessage = error.message || error.respmess;
  //       getAlertType(type, toast, respMessage);
  //     } else {
  //       const errors = error.errors?.Error;
  //       if (errors && errors.length > 0) {
  //         errors.forEach((error) => {
  //           const errorParams = error.params;
  //           if (errorParams) {
  //             if (errorParams.ErrorNumber === "104") {
  //               store.dispatch(setIsShowTransactionExpiredModal(true))
  //             } else {
  //               let errorMsg = `${errorParams?.Description || errorParams?.Message}`
  //               getAlertType(type, toast, errorMsg);
  //             }
  //           }

  //         });
  //       } else {
  //         return Promise.reject("Unrecognized error");
  //       }

  //     }
  //   } else if (typeof error === "string") {
  //     getAlertType(type, toast, error);
  //   }
  // };

  static alert = (type, error) => {
    let message = "Something went wrong";
    if (typeof error === "object") {
      if (error.message || error.respmess) {
        message = error.message || error.respmess;
      } else {
        const errors = error.errors?.Error;
        if (errors && errors.length > 0) {
          errors.forEach((error) => {
            const errorParams = error.params;
            if (errorParams) {
              if (errorParams.ErrorNumber === "104") {
                store.dispatch(setIsShowTransactionExpiredModal(true));
              } else {
                message = errorParams?.Description || errorParams?.Message;
              }
            }
          });
        } else {
          message = "Unrecognized error";
        }
      }
    } else if (typeof error === "string") {
      message = error;
    }
    if (type === "error") {
      store.dispatch(setErrorModal({ show: true, message }));
    } else {
      getAlertType(type, toast, message);
    }
  };


  static alertConfirm = (title, message, yes, no) => {
    return new Promise((resolve, reject) => {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="react-confirm-alert">
              <div className="react-confirm-alert-body">
                <div className='react-confirm-alert-title-block'>
                  <img className='mr-2' src={warningSvg} alt="" />
                  {
                    title ? <h3>{title}</h3> : null
                  }
                  <hr />
                </div>
                {
                  message ?
                    <>
                      <p>{message}</p>
                      <hr />
                    </>
                    : null
                }
                <div className="react-confirm-alert-button-group justify-content-end">
                  <button onClick={() => { reject(); onClose(); }}>{no}</button>
                  <button onClick={() => { resolve(); onClose(); }}>{yes}</button>
                </div>
              </div>
            </div>
          )
        }
      })
    })
  }

}
