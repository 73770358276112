import { useWallet } from '@solana/wallet-adapter-react';
import React from 'react';
import { extractAddress } from '../helper';

const SolanaWalletInfo = ({ selectedChain, selectedToken, allBalance }) => {

  const { wallet, connected, connect, disconnect, publicKey } = useWallet();

  return (
    <>
      {
        connected && <div className="info-block">
          <div className="text-muted text-nowrap">Status</div>
          <div className="dotes w-100" />
          <div className="text-success capitalize">Connected</div>
        </div>
      }
      {
        publicKey && <div className="info-block mt-2">
          <div className="text-muted text-nowrap">Address</div>
          <div className="dotes w-100" />
          <div className="text-muted capitalize">{extractAddress(publicKey.toBase58())}</div>
        </div>
      }
      {
        selectedChain && <div className="info-block mt-2">
          <div className="text-muted text-nowrap">Network</div>
          <div className="dotes w-100" />
          <div className="text-muted text-nowrap">
            {
              selectedChain ?
                <div className="d-flex gap-2 align-items-center">
                  <span>{selectedChain.chainName}</span>
                  <img src={selectedChain.chainLogo} width={20} className="rounded" />
                </div>
                : "Not set"
            }
          </div>
        </div>
      }
      {
        allBalance && <div className="info-block mt-2">
          <div className="text-muted text-nowrap">Amount</div>
          <div className="dotes w-100" />
          <div className="text-muted text-nowrap">
            {
              selectedToken ?
                <div className="d-flex gap-2 align-items-center">
                  <span>{allBalance?.balance}</span>
                  <img src={allBalance.currencyLogo} width={20} className="rounded" />
                </div>
                : "Not set"
            }
          </div>
        </div>
      }
    </>
  );
}

export default SolanaWalletInfo
