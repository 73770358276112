import { createSlice } from "@reduxjs/toolkit";

const mianSlice = createSlice({
  name: "main",
  initialState: {
    token: localStorage.getItem("token"),
    isShowVeriffModal: false,
    isShowBlackListModal: false,
    isShowTransactionExpiredModal: false,
    isShowOtpModal: false,
  },
  reducers: {
    setToken: (state, { payload }) => {
      if (payload) {
        localStorage.setItem("token", payload);
      } else {
        localStorage.removeItem("token")
      }
      state.token = payload
    },
    setIsShowVeriffModal: (state, action) => {
      state.isShowVeriffModal = action.payload
    },
    setIsShowBlackListModal: (state, action) => {
      state.isShowBlackListModal = action.payload
    },
    setIsShowTransactionExpiredModal: (state, action) => {
      state.isShowTransactionExpiredModal = action.payload
    },
    setIsShowOtpModal: (state, action) => {
      state.isShowOtpModal = action.payload
    },
  },
});

export const {
  setToken,
  setIsShowVeriffModal,
  setIsShowBlackListModal,
  setIsShowTransactionExpiredModal,
  setIsShowOtpModal,
} = mianSlice.actions;

export default mianSlice.reducer;
