import { createSlice } from "@reduxjs/toolkit";

const errorSlice = createSlice({
  name: "error",
  initialState: {
    isErrorModalVisible: false,
    errorMessage: "",
  },
  reducers: {
    setErrorModal: (state, { payload }) => {
      state.isErrorModalVisible = payload.show;
      state.errorMessage = payload.message;
    },
  },
});

export const {
  setErrorModal,
} = errorSlice.actions;

export default errorSlice.reducer;
