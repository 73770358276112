import 'react-phone-input-2/lib/style.css'
import React, { memo, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import { Button } from 'react-bootstrap';
import ApiService from '../../../../Services/ApiService';
import AlertService from '../../../../Services/AlertService';
import { Link } from 'react-router-dom';
import OtpInput from 'react-otp-input'

const KycVerification = memo(({
  isLoading,
  setIsLoading,
  countries,
  isTransactionValidated,
  getFail,
  kyc_numbers,
  set_kyc_numbers,
  showOTPloader,
  setActiveTab
}) => {

  const [isShowLoader, setIsShowLoader] = useState(false);
  const [isKycSent, setIsKycSent] = useState(false);
  const [values, setValues] = useState({
    phoneNumber: "",
  })

  const onPhoneNumberChange = (event, fieldName) => {
    setValues(values => ({ ...values, [fieldName]: event }))
  };

  const sendKycData = () => {
    setIsLoading(true);
    setIsShowLoader(true);
    const data = {
      phone: values.phoneNumber,
    }
    ApiService.sendKycData(data).then(() => {
      AlertService.alert("success", "The verification code has been sent.");
      setIsKycSent(true);
    }).catch(error => getFail(error)).finally(() => {
      setIsShowLoader(false);
      setIsLoading(false);
    });
  }

  return (

    <div>
      <div className="mb-3 title-block"><strong>OTP</strong></div>
      <div className="mt-3 p-3">

        <div>
          <p>
            <b className='text-muted'>
              {
                isTransactionValidated ? "OTP (One Time Password) verified" : "OTP (One Time Password) verification"
              }
            </b>
          </p>
          <div className={`${isTransactionValidated ? "disabled" : ""}`}>
            <small>Please enter your phone number to continue.</small>
            <div className="cx-phone-number-block mb-1">
              {
                countries && countries.length ?
                  <PhoneInput
                    country={values.selectedCountryId ? countries.find(item => item.id === values.selectedCountryId)?.isoCode2.toLowerCase() : "hk"}
                    value={values.phoneNumber}
                    className={`custom-phone-number-input-block ${isLoading ? " disabled" : ""}`}
                    onChange={(event) => onPhoneNumberChange(event, "phoneNumber")}
                    disabled={isTransactionValidated}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        if (isKycSent) { return false; }
                        sendKycData();
                      }
                    }}
                  />
                  : null
              }
            </div>
            <div className="d-lg-flex form-group justify-content-end">
              <Button
                id="send_kyc"
                variant='primary'
                className={`
                mt-2 mx-0 px-4
                ${!values.phoneNumber ||
                    isLoading ||
                    isShowLoader ||
                    isTransactionValidated || isKycSent ?
                    "disabled" :
                    ""}`
                }
                onClick={sendKycData}
              >
                {
                  isShowLoader ? "Loading..." : "Send"
                }
              </Button>
            </div>
            {
              isKycSent ?
                <div className='d-flex justify-content-center'>
                  <Link
                    className='text-center'
                    to="#"
                    onClick={() => {
                      setValues((values) => ({
                        ...values,
                        phoneNumber: ""
                      }));
                      setIsKycSent(false);
                    }}>
                    Try Again
                  </Link>
                </div>
                : null
            }
          </div>
        </div>
        <hr />
        <div className={`${!isKycSent && !isTransactionValidated ? "disabled" : ""}`}>
          <p>
            <b className='text-muted'>
              {
                isTransactionValidated ?
                  "SMS verified." :
                  " Please, type OTP Code"
              }
            </b>
          </p>
          <div className={`${isTransactionValidated ? "disabled" : ""}`}>
            <small>Enter the code sent to your phone to proceed.</small>
            <div className="cx-accordion-body-list cx-overflow-inherit position-relative bg-white">
              <div
                className={`cx-accordion-body-number-item  ${showOTPloader || isTransactionValidated ? "disabled" : ""}`}
              >
                <OtpInput
                  value={kyc_numbers}
                  onChange={set_kyc_numbers}
                  inputType="number"
                  numInputs={8}
                  shouldAutoFocus={isKycSent && !isTransactionValidated ? true : false}
                  renderSeparator={<span>&nbsp;</span>}
                  renderInput={(props, index) => <input {...props} id={`kyc_${index}`} className="kyc-number" disabled={showOTPloader} />}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <hr />
          <p>
            <b className='text-muted'>
              Make Payment
            </b>
          </p>
          {/* <div className='mt-3'>
            <small>
              To make a payment, select the
              &nbsp;<b
                style={{ cursor: `${isTransactionValidated ? "pointer" : "default"}`, textDecoration: `${isTransactionValidated ? "underline" : "none"}` }}
                onClick={() => {
                  if (isTransactionValidated) {
                    setActiveTab(2)
                  }
                }}
              >
                network & token
              </b>,
              connect your
              &nbsp;<b
                style={{ cursor: `${isTransactionValidated ? "pointer" : "default"}`, textDecoration: `${isTransactionValidated ? "underline" : "none"}` }}
                onClick={() => {
                  if (isTransactionValidated) {
                    setActiveTab(3)
                  }
                }}
              >
                wallet
              </b>,
              and complete the
              <b>&nbsp;OTP&nbsp;</b>
              verification. After that, you will be able to proceed with the payment process.
            </small>
          </div> */}
          <div className='mt-3'>
            <small>
              To make a payment, complete the
              <b>&nbsp;OTP&nbsp;</b>
              verification, select the
              &nbsp;<b
                style={{ cursor: `${isTransactionValidated ? "pointer" : "default"}`, textDecoration: `${isTransactionValidated ? "underline" : "none"}` }}
                onClick={() => {
                  if (isTransactionValidated) {
                    setActiveTab(2);
                  }
                }}
              >
                network and token
              </b>, and connect your
              &nbsp;<b
                style={{ cursor: `${isTransactionValidated ? "pointer" : "default"}`, textDecoration: `${isTransactionValidated ? "underline" : "none"}` }}
                onClick={() => {
                  if (isTransactionValidated) {
                    setActiveTab(3);
                  }
                }}
              >
                wallet
              </b>. After that, you will be able to proceed with the payment process.
            </small>
          </div>

        </div>
      </div>
    </div>
  )
})

export default KycVerification;