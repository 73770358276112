import { useSelector, useDispatch } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { setErrorModal } from "../../Store/Reducers/errorReducer";
import { MdOutlineErrorOutline } from "react-icons/md";


const ErrorModal = () => {
  const dispatch = useDispatch();
  const { isErrorModalVisible, errorMessage } = useSelector(state => state.error);

  const closeModal = () => {
    dispatch(setErrorModal({ show: false, message: "" }))
  }

  return (
    <Modal
      show={isErrorModalVisible}
      onHide={closeModal}
      centered
      scrollable
    >
      <Modal.Body>
        <div className="d-flex gap-2 align-items-center">
          <div className="d-flex gap-2 align-items-center">
            <MdOutlineErrorOutline color="#DC3545" size={30} />
          </div>
          <p className="text-danger mb-0" style={{ fontWeight: "500" }}>{errorMessage}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end">
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorModal;
