import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

export default function TransactionExpiredModal({ show = false, paymentData = null }) {

  const { isShowTransactionExpiredModal, cancelUrl } = useSelector(state => state.main);

  let _cancelUrl = paymentData?.cancelUrl || cancelUrl || null;

  const redirectToCancelUrl = () => {
    if (!_cancelUrl) {
      return false;
    }
    localStorage.clear();
    window.location.href = _cancelUrl;
  }

  return (
    <Modal show={show || isShowTransactionExpiredModal} onHide={redirectToCancelUrl} centered>
      <Modal.Header closeButton={_cancelUrl}>
        <Modal.Title>Transaction expired</Modal.Title>
      </Modal.Header>
      <Modal.Body><div className='py-2'><p className='text-center'>Your transaction expired, please go to merchant and make a new one.</p></div></Modal.Body>
      <Modal.Footer>

        <Button variant="secondary" onClick={redirectToCancelUrl}>OK</Button>
      </Modal.Footer>
    </Modal>
  )
}
