import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsShowBlackListModal } from '../../Store/Reducers/mainReducer';
import ApiService from '../../Services/ApiService';
import AlertService from '../../Services/AlertService';
import { Button, Modal } from 'react-bootstrap'
import { IoMdClose } from 'react-icons/io';

const titleMap = {
  0: 'Your Phone Number is Blacklisted',
  1: 'Your Wallet Address is Blacklisted',
  2: 'Your IP Address is Blacklisted',
  3: 'Your Country is Blacklisted',
  4: 'Your Phone Code is Blacklisted',
  5: 'Your City is Blacklisted',
};

export default function BlackListComponent({ paymentData, walletAddress }) {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);
  const { isShowBlackListModal } = useSelector(state => state.main);

  const [errorMessage, setErrorMessage] = useState('');
  const [errorTitle, setErrorTitle] = useState('');

  const handleClose = () => {
    dispatch(setIsShowBlackListModal(false));
    localStorage.removeItem("isShowBlackListModal")
    if (paymentData && paymentData.cancelUrl) {
      window.location.href = paymentData?.cancelUrl;
    }
  };

  useEffect(() => {
    if (isShowBlackListModal) {
      return false;
    } else {
      if (user) {
        checkUserBlackList();
      } else {
        checkBlackListByIp();
      }
    }
    return () => { }
  }, [user, isShowBlackListModal])

  const checkBlackListByIp = () => {
    if (isShowBlackListModal) return false;
    ApiService.checkBlackListByIp().then(response => {
      if (response.data) {
        const { wallet, country, city, ipaddress } = response.data;
        let blacklistType = null;
        if (wallet) {
          blacklistType = wallet.type;
        } else if (country) {
          blacklistType = country.type;
        } else if (city) {
          blacklistType = city.type;
        } else if (ipaddress) {
          blacklistType = ipaddress.type;
        }
        if (blacklistType !== null) {
          setErrorTitle(titleMap[blacklistType]);
          dispatch(setIsShowBlackListModal(true));
        }
      }
    }).catch(error => AlertService.alert("error", error))
  }

  const checkUserBlackList = () => {
    if (isShowBlackListModal) return false;
    if (!user) { return false; }
    if (user.country) {
      ApiService.checkBlackListByCountry(user.country).then((response) => {
        const { wallet, country, city, ipaddress } = response?.data.data;
        const { errors } = response?.data;
        setBlackListType(wallet, country, city, ipaddress);
        if (wallet || country || city || ipaddress) {
          return false;
        }
      }).catch(error => AlertService.alert("error", error))
    }
    if (user.phoneNumber) {
      ApiService.checkBlackList(user.phoneNumber).then((response) => {
        const { wallet, country, city, ipaddress } = response?.data.data;
        const { errors } = response?.data;
        setBlackListType(wallet, country, city, ipaddress);
        if (wallet || country || city || ipaddress) {
          return false;
        }
      }).catch(error => AlertService.alert("error", error))
    }
    if (walletAddress) {
      ApiService.checkBlackList(walletAddress).then((response) => {
        const { wallet, country, city, ipaddress } = response?.data.data;
        const { errors } = response?.data;
        setBlackListType(wallet, country, city, ipaddress);
        if (wallet || country || city || ipaddress) {
          return false;
        }
      }).catch(error => AlertService.alert("error", error))
    }
  }

  const setBlackListType = (wallet, country, city, ipaddress) => {
    let blacklistType = null;
    if (wallet) {
      blacklistType = wallet.type;
    } else if (country) {
      blacklistType = country.type;
    } else if (city) {
      blacklistType = city.type;
    } else if (ipaddress) {
      blacklistType = ipaddress.type;
    }
    if (blacklistType !== null) {
      localStorage.setItem("isShowBlackListModal", "1")
      setErrorTitle(titleMap[blacklistType]);
      dispatch(setIsShowBlackListModal(true));
    }
  }

  return (
    <div>
      <Modal
        show={isShowBlackListModal}
        onHide={handleClose}
        className='blacklist-modal'
        backdrop="static"
        // keyboard={false}
        centered
      >
        <Modal.Header
          style={{ border: "none" }}
        >
          <Modal.Title>{errorTitle}</Modal.Title>
          <div className='close-btn'>
            <IoMdClose size={25} onClick={handleClose} style={{ cursor: "pointer" }} />
          </div>
        </Modal.Header>
        <Modal.Body>
          {errorMessage}
        </Modal.Body>
        <Modal.Footer style={{ border: "none" }}>
          <Button variant="light" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
