import React, { useState, useEffect } from 'react'
import TransactionExpiredModal from '../../../../Components/TransactionExpired/TransactionExpiredModal';
import { BiTimer } from 'react-icons/bi';


// const ExampleComponent = memo(({ paymentData }) => (
//     <div className="cx-modal-body-wrapper txPart">
//         <p><strong className="cx-d-block cx-spiner-text">Transaction has been cancelled successfully</strong></p>
//         <Link to="#" onClick={() => window.location.href = paymentData.cancelUrl} className="my-2 d-block">
//             Back to merchant
//         </Link>
//     </div>
// ));


const TransactionCounter = ({ paymentData }) => {

  const [countdown, setCountdown] = useState('');
  const [isFinished, setIsFinished] = useState(false);

  useEffect(() => {
    const paymentPageOpenedDeadline = paymentData?.paymentPageOpenedDeadline;
    const targetDate = new Date(paymentPageOpenedDeadline.includes("Z") ? paymentPageOpenedDeadline : paymentPageOpenedDeadline + 'Z');
    const countdownInterval = setInterval(() => {
      const currentDate = new Date();
      const remainingTime = targetDate - currentDate;
      if (remainingTime <= 0) {
        clearInterval(countdownInterval);
        setIsFinished(true);
        setCountdown('Countdown has ended');
      } else {
        const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
        const countdownString = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        setCountdown(countdownString);
      }
    }, 1000);
    return () => {
      clearInterval(countdownInterval);
    };
  }, [paymentData, paymentData?.paymentPageOpenedDeadline]);

  return (
    <>
      {
        isFinished ?
          <TransactionExpiredModal show={true} paymentData={paymentData} />
          : null
      }
      {
        isFinished ?
          <small className="bold text-danger">Transaction expired</small>
          : <div className="mb-3 bold d-flex align-items-center">
            <small>
              Transaction will expire:&nbsp;
            </small>
            <BiTimer size={24} className='me-1' />
            <small>
              {countdown}
            </small>
          </div>
      }
    </>


  );
}

export default TransactionCounter