import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap"
import NoData from "./../Components/NoData/NoData"
import { STELLAR_PLATFORM_TYPE } from "../Constants/MainKeys";
import { ModalContext } from "../Pages/Layaut";

function Footer({ chains }) {

    const { modalContent, setModalContent } = useContext(ModalContext)

    const getUrlExtension = () => {
        return window.location.hostname.split(/[#?]/)[0].split('.').pop().trim().toLowerCase();
    }

    const handleClose = () => {
        setModalContent({
            show: false,
            title: "",
            children: null,
        })
    }

    const onSetContactUs = () => {
        setModalContent({
            show: true,
            title: "Contact Us",
            children: <div>
                <NoData width={200} />
            </div>,
        })
    }
    const onSetFaq = () => {
        setModalContent({
            show: true,
            title: "FAQ",
            children: <div>
                <NoData width={200} />
            </div>,
        })
    }

    const onSetNetworks = () => {
        setModalContent({
            show: true,
            title: "Supported Networks",
            children: <div>
                {
                    chains && chains.length ?
                        <ul className="cx-accordion-body-list flex-wrap" id="crypto-block">
                            {
                                chains.map(item => {
                                    return <li
                                        key={item.id}
                                        title={item.chainName}
                                        className={`cx-accordion-list-item px-3 pt-3 mb-3 ${item.platformType === STELLAR_PLATFORM_TYPE ? "disabled" : ""}`}
                                    >
                                        <img src={item.chainLogo} alt="logo" className='rounded' />
                                        <div className="cx-accordion-list-item-name">
                                            {item.chainSlug}
                                        </div>
                                    </li>
                                })
                            }
                        </ul> : <NoData width={200} />
                }
            </div>
        })
    }

    const onSetTokens = () => {
        const tokens = [];
        const seenSlugs = new Set();
        chains && chains.length && chains.forEach(chain => {
            chain.tokens.forEach(token => {
                if (!seenSlugs.has(token.slug)) {
                    seenSlugs.add(token.slug);
                    tokens.push(token);
                }
            });
        });

        setModalContent({
            show: true,
            title: "Supported Tokens",
            children: <div>
                {
                    tokens && tokens.length ?
                        <ul className="cx-accordion-body-list" id="crypto-block">
                            {
                                tokens.map(item => {
                                    return <li
                                        title={item.name}
                                        key={item.id}
                                        className={`cx-accordion-list-item pt-3`}>
                                        <img src={item.logo} alt="logo" className='rounded' />
                                        <div className="cx-accordion-list-item-name" >
                                            {item?.slug}
                                        </div>
                                    </li>
                                })
                            }
                        </ul>
                        : <NoData width={200} />
                }
            </div>,
        });
    };

    const onSetTermsConditions = () => {
        setModalContent({
            show: true,
            title: "Terms & Conditions",
            children: <div>
                <NoData width={200} />
            </div>,
        })
    }

    const onSetPrivacyPolicy = () => {
        setModalContent({
            show: true,
            title: "Privacy Policy",
            children: <div>
                <NoData width={200} />
            </div>,
        })
    }

    return (
        <footer className="cx-footer px-5">
            <ModalComponent content={modalContent} handleClose={handleClose} />
            <div className="d-flex justify-content-between align-items-center flex-wrap">
                <ul className="cx-fotter-options">
                    <li className="cx-footer-item"><Link to="#" onClick={onSetContactUs}>Contact Us</Link></li>
                    <li className="cx-footer-item"><Link to="#" onClick={onSetFaq}>FAQ</Link></li>
                    <li className="cx-footer-item"><Link to="#" onClick={onSetNetworks}>Supported Networks</Link></li>
                    <li className="cx-footer-item"><Link to="#" onClick={onSetTokens}>Supported Tokens</Link></li>
                    <li className="cx-footer-item"><Link to="#" onClick={onSetTermsConditions}>Terms & Conditions</Link></li>
                    <li className="cx-footer-item"><Link to="#" onClick={onSetPrivacyPolicy}>Privacy Policy</Link></li>
                </ul>
                <div className="powered my-2"><span>Powered by <Link to={`https://cryllex.${getUrlExtension()}`} className="text-primary" style={{ textDecoration: "none" }} target="_blank">&nbsp;<span style={{ fontWeight: "500" }}>Cryllex</span>&nbsp;</Link></span></div>
            </div>
        </footer>
    );
}

export default Footer;


const ModalComponent = ({ content, handleClose }) => {
    return <Modal centered show={content.show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>{content.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {content.children}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
        </Modal.Footer>
    </Modal>
}













