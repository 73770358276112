import React, { useEffect, useState, memo } from "react"
import { Accordion, Button, Col, Row } from 'react-bootstrap'
import MainPrice from "./components/MainPrice/_MainPrice"
import WalletConnectPayBtn from "./components/PayBtn/WalletConnectPayBtn"
import ApiService from '../../Services/ApiService'
import AlertService from '../../Services/AlertService'
import MainService from '../../Services/MainService'
import { useDispatch, useSelector } from 'react-redux'
import TransactionWaiting from "../TransactionWaiting"
import TopLogo from "../TopLogo"
import $ from "jquery"
import * as signalR from '@microsoft/signalr'
import KycVerification from "./components/KycVerification/KycVerification"
import { useDebounce } from 'use-debounce'
import NetworksAndCoins from "./components/NetworksAndCoins/_NetworksAndCoins"
import WalletConnectWalletConfig from "./components/WalletConfigs/WalletConnectWalletConfig"
import SolanaWalletConfig from "./components/WalletConfigs/SolanaWalletConfig"
import StellarWalletConfig from "./components/WalletConfigs/StellarWalletConfig"
import TronWalletConfig from "./components/WalletConfigs/TronWalletConfig"
import { AVALANCHE_PLATFORM_TYPE, ETHEREUM_PLATFORM_TYPE, SOLANA_PLATFORM_TYPE, STELLAR_PLATFORM_TYPE, TRON_PLATFORM_TYPE } from "../../Constants/MainKeys"
import SolanaPayBtn from "./components/PayBtn/SolanaPayBtn"
import TronPayBtn from "./components/PayBtn/TronPayBtn"
import StellarPayBtn from "./components/PayBtn/StellarPayBtn"
import BlackListComponent from "../../Components/BlackList/BlackListComponent"
import VeriffModal from "../../Components/Veriff/VeriffModal"
import TransactionCounter from "./components/TransactionCounter/TransactionCounter"
import { Link } from "react-router-dom"
import { BiNetworkChart } from "react-icons/bi";
import { LuWallet } from "react-icons/lu";
import { MdOutlineSystemSecurityUpdateGood } from "react-icons/md";
import { FaLock } from "react-icons/fa";
import { setCancelUrl, setUserData, setUserToken } from "../../Store/Reducers/userReducer"


const Main = memo(({
  connection,
  signal_update,
  chains,
  selectedToken,
  selectedChain,
  onSetNetwork,
  onSetCrypto,
  countries,
  isShowNetworkLoading,
  isShowTokenLoading,
  setSelectedChain,
  setSelectedToken,
  activeTab,
  setActiveTab
}) => {

  var isPaymentCancelled = false;
  const dispatch = useDispatch();
  const { token } = useSelector(state => state.main);
  const [isLoading, setIsLoading] = useState(false);
  const [showOTPloader, setShowOTPloader] = useState(false);
  const [price, setPrice] = useState(null);
  const [tick, setTick] = useState(null);
  const [organizationData, setOrganizationData] = useState(null);
  const [isTransactionValidated, setIsTransactionValidated] = useState(false);
  const [isShowTransactionWaitingModal, setIsShowTransactionWaitingModal] = useState(false);
  const [kyc_numbers, set_kyc_numbers] = useState("");
  const [debounce_kyc_numbers] = useDebounce(kyc_numbers, 500);
  const [paymentData, setPaymentData] = useState(null);
  const [to, setTo] = useState(null);
  const [allBalance, setAllBalance] = useState(null);
  const [amount, setAmount] = useState(0);
  const [address, setAddress] = useState("");
  const [isLoadingPay, setIsLoadingPay] = useState(false);

  useEffect(() => {
    if (debounce_kyc_numbers.length === 8) {
      validateKyc(debounce_kyc_numbers);
    }
  }, [debounce_kyc_numbers])

  useEffect(() => {
    if (connection && connection.state === signalR.HubConnectionState.Connected) {
      init();
      connection.on("updatecrypto", (id, _price) => {
        try {
          if (_price) {
            setPrice(JSON.parse(_price).price)
            setTick(JSON.parse(_price).tick)
          }
        } catch (error) {
          AlertService.alert("error", error)
        }
      });
    }

    // }, [connection, selectedChain, selectedToken])
  }, [connection, selectedToken])

  useEffect(() => {
    if (address) {
      getAccountBalance(address);
    }
  }, [address, selectedChain])

  const getAccountBalance = async (address) => {
    if (!address || !selectedChain) { return false; }
    ApiService.getAccountBalance(address, selectedChain.id).then(response => {
      if (response && response.data && response.data.data) {
        setAllBalance(response.data.data)
      }
    }).catch(error => getFail(error))
  }

  const init = () => {
    try {
      setTimeout(() => {
        verifyIsKycAlreadyDone();
      }, 500);
    } catch (e) {
      setTimeout(() => { init(); }, 1000);
    }
  }

  const verifyIsKycAlreadyDone = () => {
    ApiService.verifyIsKycAlreadyDone().then(response => {
      if (response && response.data) setIsTransactionValidated(true);
    }).catch(error => getFail(error));
  }

  useEffect(() => {
    getPaymentData();
  }, [selectedChain]);


  const getPaymentData = (_loadOrgData) => {
    if (_loadOrgData == undefined) _loadOrgData = true;
    if (!token) { return false; }
    ApiService.getPaymentData(localStorage.getItem("token")).then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        if (data.cancelUrl) {
          dispatch(setCancelUrl(data.cancelUrl))
          if (isPaymentCancelled) {
            window.location.href = data.cancelUrl;
          }
        }
        onSetSelectedNetworkAndToken(data);
        successFunc(data, _loadOrgData);
      };
    }).catch(error => {
      getFail(error)
    })
  }

  const onSetSelectedNetworkAndToken = (paymentData) => {
    if (!paymentData?.cryptoNetworkId || !chains.length) return false;
    const currentNetwork = chains.find(({ id }) => id === paymentData.cryptoNetworkId);
    if (!currentNetwork) return false;
    setSelectedChain(currentNetwork);
    const currentToken = currentNetwork.tokens?.find(({ id }) => id === paymentData.cryptoCurrencyId);
    if (currentToken) setSelectedToken(currentToken);
  };

  const successFunc = (data, _loadOrgData) => {
    setPaymentData(null);
    setIsShowTransactionWaitingModal(false);
    setTimeout(() => {
      setPaymentData(data);
      data.cryptoAmount && setPrice(data.cryptoAmount);
      if (data.paymentRequested) {
        setIsShowTransactionWaitingModal(true);
        checkPaymentStatus(data);
      }
      if (_loadOrgData) {
        loadOrgData(data.organizationId);
      }
    }, 500);
  };

  const checkPaymentStatus = (paymentData) => {
    if (!paymentData) return false;

    if ([3, 4, 6, 7].includes(paymentData.status)) return false;

    try {
      const paymentTime = new Date(paymentData.paymentRequestedDatetime);
      const localPaymentTime = MainService.convertUTCDateToLocalDate(paymentTime);
      const passedSeconds = Math.floor((Date.now() - localPaymentTime.getTime()) / 1000);

      if (passedSeconds > 300 && isShowTransactionWaitingModal && paymentData.status === 0) {
        cancelPayment(false);
        return;
      }
    } catch (error) {
      AlertService.alert("error", error);
      return;
    }

    setTimeout(async () => {
      try {
        const response = await ApiService.getPaymentData(token);
        if (response?.data) {
          setPaymentData({ ...response.data });
          checkPaymentStatus(response.data);
        }
      } catch (error) {
        AlertService.alert("error", error);
      }
    }, 1000);
  };


  const loadOrgData = (organizationId) => {
    setIsLoading(true);
    ApiService.loadOrgData(organizationId).then(response => {
      if (response && response.data) {
        setOrganizationData(response.data.organizationDetail)
      }
    }).catch(error => getFail(error)).finally(() => {
      setIsLoading(false)
    });
  }

  const validateKyc = (data) => {
    setShowOTPloader(true);
    ApiService.validateKyc({ Code: data.toString() }).then(response => {
      if (response && response.data) {
        setIsTransactionValidated(true);
        verifyIsKycAlreadyDone();
        setActiveTab(2);

        dispatch(setUserToken(response.data));
        setTimeout(() => {
          getCurrentUser();
        }, 100);

      } else {
        AlertService.alert("error", "OTP code is invalid");
      }
      setTimeout(() => {
        setShowOTPloader(false);
      }, 1000);
    }).catch(error => getFail(error)).finally(() => {
      setTimeout(() => {
        clearOTPInputs();
      }, 1000);
    })
  }

  const getCurrentUser = () => {
    ApiService.getCurrentUser().then(response => {
      if (response && response.data) {
        dispatch(setUserData(response.data));
      }
    }).catch(error => AlertService.alert("error", error))
  }

  const pay = (cb) => {

    if (!selectedChain) {
      AlertService.alert("warning", "Please select any network.");
      return false;
    }

    if (!selectedToken) {
      AlertService.alert("warning", "Please select any token.");
      return false;
    }

    setIsLoadingPay(true);
    ApiService.pay().then(resp => {
      if (resp && resp.data) {
        const data = { ...resp.data }
        signal_update();
        setTo(data.account);
        setAmount(data.amount);
        getPaymentData(false);
        cb && cb(data.account, data.amount);
      }
    }).catch(error => getFail(error)).finally(() => {
      setIsLoadingPay(false);
    });
  }

  const paySuccess = (txHash = "", chainId = null,) => {
    if (!txHash) { return false; }
    let data = {
      txHash,
      chainId
    }
    ApiService.paySuccess(data).then(() => {
      signal_update();
      // getPaymentData(false);
    }).catch(error => getFail(error))
  }

  const payError = (err_code, err_message) => {
    ApiService.payError({ Code: `${err_code}`, Message: err_message }).then(() => {
      getPaymentData(false);
      signal_update();
      // AlertService.alert("error", `${err_code} : ${err_message}`);
    }).catch(error => getFail(error ? error : err_message))
  }

  const cancelPayment = (showConfirm = true) => {
    const cancel = () => {
      ApiService.cancelPayment(token).then(() => {
        isPaymentCancelled = true;
        getPaymentData(false);
        setIsLoading(false);
        setIsTransactionValidated(false);
        setShowOTPloader(false);
        clearOTPInputs();
      });
    };

    if (showConfirm) {
      AlertService.alertConfirm(
        `Payment cancellation`,
        "Are you sure you want to cancel this payment?",
        "Yes",
        "No"
      ).then(() => {
        cancel();
      }).catch(() => { });
    } else {
      cancel();
    }
  };

  const getFail = (error) => {
    error && AlertService.alert("error", error);
    isPaymentCancelled = false;
    setIsLoading(false);
    // clearOTPInputs();
    setTimeout(() => {
      setShowOTPloader(false);
    }, 1000);
  }

  const clearOTPInputs = () => {
    set_kyc_numbers("");
    if ($(document.querySelectorAll('.kyc-number')[0])) {
      setTimeout(() => {
        $(document.querySelectorAll('.kyc-number')[0]).focus();
      }, 100);
    };
  }

  const setPayButtonComponent = () => {
    const commonProps = {
      price,
      paymentData,
      isTransactionValidated,
      pay,
      selectedChain,
      selectedToken,
      amount,
      paySuccess,
      payError,
      to,
      isLoadingPay,
    };
    switch (selectedChain?.platformType) {
      case AVALANCHE_PLATFORM_TYPE:
      case ETHEREUM_PLATFORM_TYPE:
        return <WalletConnectPayBtn {...commonProps} />
      case SOLANA_PLATFORM_TYPE:
        return <SolanaPayBtn {...commonProps} />
      case STELLAR_PLATFORM_TYPE:
        return <StellarPayBtn  {...commonProps} />
      case TRON_PLATFORM_TYPE:
        return <TronPayBtn {...commonProps} />
      default:
        return <div></div>;
    }
  };

  const setWalletConfigComponent = () => {
    const commonProps = {
      amount,
      allBalance,
      selectedChain,
      selectedToken,
      setAddress,
      setActiveTab,
    };
    if (selectedChain) {

      switch (selectedChain?.platformType) {
        case AVALANCHE_PLATFORM_TYPE:
        case ETHEREUM_PLATFORM_TYPE:
          return <WalletConnectWalletConfig {...commonProps} />
        case SOLANA_PLATFORM_TYPE:
          return <SolanaWalletConfig {...commonProps} />
        case STELLAR_PLATFORM_TYPE:
          return <StellarWalletConfig {...commonProps} />
        case TRON_PLATFORM_TYPE:
          return <TronWalletConfig {...commonProps} />
        default:
          break;
      }
    } else {
      return <div>
        <div className="title-block">
          <strong >
            Connect your wallet
          </strong>
        </div>
        <div className='mt-3'>
          <div className="p-3 text-dark">
            <p>
              Before connecting your wallet, first select the <Link className="text-primary" to="#" onClick={() => setActiveTab(2)}>network & token</Link> you want to use. Once selected, the connection options will become available.
            </p>
            <p>
              We use different connection methods depending on the network:
            </p>
            <ul>
              <li>
                Some blockchains support mobile and desktop wallets with a convenient QR code scanning option.
              </li>
              <li>
                In other cases, browser extensions are available for secure interaction with the network.
              </li>
              <li>
                Certain ecosystems offer dedicated wallets that operate directly within their environment.
              </li>
            </ul>
            <p>
              After selecting a network, all available connection options will be displayed.
            </p>
          </div>
        </div>
      </div>
    }
  };

  const setContent = () => {
    switch (activeTab) {
      case 1:
        return <div>
          <KycVerification
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            isTransactionValidated={isTransactionValidated}
            countries={countries}
            paymentData={paymentData}
            getFail={getFail}
            kyc_numbers={kyc_numbers}
            set_kyc_numbers={set_kyc_numbers}
            showOTPloader={showOTPloader}
            setActiveTab={setActiveTab}
          />
        </div>
      case 2:
        return <div>
          <NetworksAndCoins
            chains={chains}
            selectedChain={selectedChain}
            selectedToken={selectedToken}
            onSetNetwork={onSetNetwork}
            onSetCrypto={onSetCrypto}
            isShowNetworkLoading={isShowNetworkLoading}
            isShowTokenLoading={isShowTokenLoading}
            setActiveTab={setActiveTab}
          />
        </div>
      case 3:
        return <div>
          {setWalletConfigComponent()}
        </div>
      default:
        break;
    }
  }


  return <main>
    <div className="px-lg-5 px-3 ">
      <TopLogo />
      <VeriffModal countries={countries} paymentData={paymentData} />
      <BlackListComponent
        paymentData={paymentData}
        walletAddress={address}
      />
      <TransactionWaiting
        connection={connection}
        paymentData={paymentData}
        cancelPayment={cancelPayment}
        isShowTransactionWaitingModal={isShowTransactionWaitingModal}
      />
      <Row className="my-4">
        <Col lg={4} className="p-0">
          <MainPrice
            paymentData={paymentData}
            tick={tick}
            price={price}
            organizationData={organizationData}
            selectedChain={selectedChain}
            selectedToken={selectedToken}
          />
        </Col>
        <Col lg={8} className="p-0">
          <div className='main-content'>
            <div className="transaction-expire-block">
              {
                paymentData ?
                  <TransactionCounter
                    paymentData={paymentData}
                  />
                  : null
              }
            </div>
            <div className="popup">
              <div className="tabs">
                <React.Fragment>
                  <input
                    type="radio"
                    id="tab1"
                    name="tab"
                    checked={activeTab === 1 ? true : false}
                    onChange={() => setActiveTab(1)}
                  />
                  <label
                    className={`label ${isTransactionValidated ? "active-label" : ""}`}
                    htmlFor="tab1"
                  >
                    <span className="d-none d-lg-block">OTP</span>
                    <span className="d-block d-lg-none"><MdOutlineSystemSecurityUpdateGood size={30} /></span>
                  </label>
                </React.Fragment>
                <React.Fragment>
                  <input
                    type="radio"
                    id="tab2"
                    name="tab"
                    checked={activeTab === 2 ? true : false} onChange={() => setActiveTab(2)}
                    disabled={!isTransactionValidated}
                  />
                  <label
                    className={`label d-flex align-items-center justify-content-end gap-2 ${selectedToken && selectedChain ? "active-label" : ""}`}
                    htmlFor="tab2"
                  >
                    {
                      !isTransactionValidated ? <FaLock size={18} /> : null
                    }
                    <span className="d-none d-lg-block text-nowrap">Network & Token</span>
                    <span className="d-block d-lg-none"><BiNetworkChart size={30} /></span>
                  </label>
                </React.Fragment>
                <React.Fragment>
                  <input
                    type="radio"
                    id="tab3"
                    name="tab"
                    checked={activeTab === 3 ? true : false} onChange={() => setActiveTab(3)}
                    disabled={!isTransactionValidated}
                  />
                  <label
                    className={`label d-flex align-items-center justify-content-end gap-2 ${address ? "active-label" : ""}`}
                    htmlFor="tab3"
                  >
                    {
                      !isTransactionValidated ? <FaLock size={18} /> : null
                    }
                    <span className="d-none d-lg-block text-nowrap">Connect Wallet</span>
                    <span className="d-block d-lg-none"><LuWallet size={30} /></span>
                  </label>
                </React.Fragment>
                <div className="marker">
                  <div id="top"></div>
                  <div id="bottom"></div>
                </div>
              </div>
              <div className='content-block'>
                {setContent()}
              </div>
              <div className="prev-next-block">
                {
                  [1, 2, 3].includes(activeTab) ?
                    setPayButtonComponent()
                    : null
                }
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  </main>
})


export default Main;
