// // App.js
// import React from 'react'

// import { createWeb3Modal } from '@web3modal/wagmi/react'
// import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'
// import { WagmiProvider } from 'wagmi'
// import { QueryClient, QueryClientProvider } from '@tanstack/react-query'


// const queryClient = new QueryClient();

// const PROJECT_ID = process.env.REACT_APP_PROJECT_ID;

// const metadata = {
//   name: 'Web3Modal',
//   description: 'WalletConnect',
//   url: 'https://cryllex.com',
//   icons: ['https://avatars.githubusercontent.com/u/37784886']
// }

// const WalletConnectComponent = ({ chains = [], children }) => {

//   const config = defaultWagmiConfig({
//     chains, // required
//     projectId: PROJECT_ID, // required
//     metadata, // required

//     // enableWalletConnect: true,
//     // enableCoinbase: false,
//     // auth: {
//     //   email: false,
//     // }
//   });

//   createWeb3Modal({
//     wagmiConfig: config,
//     projectId: PROJECT_ID,
//     enableAnalytics: true,
//   });

//   return (
//     <WagmiProvider config={config} >
//       <QueryClientProvider client={queryClient}>
//         {children}
//       </QueryClientProvider>
//     </WagmiProvider>
//   );
// }

// export default WalletConnectComponent;




// WalletConnectComponent.js
import React from 'react';
import { createWeb3Modal } from '@web3modal/wagmi/react';
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config';
import { WagmiProvider } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import WalletConnectContext from './WalletConnectContext';

const queryClient = new QueryClient();

const PROJECT_ID = process.env.REACT_APP_PROJECT_ID;

const metadata = {
  name: 'Web3Modal',
  description: 'WalletConnect',
  url: 'https://cryllex.com',
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const WalletConnectComponent = ({ chains = [], children }) => {

  const config = defaultWagmiConfig({
    chains, // required
    projectId: PROJECT_ID, // required
    metadata, // required
    auth: {
      email: false,
      socials: [],
      showWallets: true,
      walletFeatures: true,
    }
  });

  createWeb3Modal({
    wagmiConfig: config,
    projectId: PROJECT_ID,
    enableAnalytics: true,
  });

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <WalletConnectContext.Provider value={config}>
          {children}
        </WalletConnectContext.Provider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default WalletConnectComponent;
