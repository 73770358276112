import React, { createContext, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import PageSpinner from "../Components/Spinners/pageSpinner";
import 'react-toastify/dist/ReactToastify.css';
import LogoAnimation from "../Components/Animation/LogoAnimation";
import { setIsShowAuthModal, setUserData, setUserToken } from "../Store/Reducers/userReducer";

export const ModalContext = createContext(null);

function Layaut(props) {

  const dispatch = useDispatch()
  const navigate = useNavigate();
  let storageToken = localStorage.getItem("token");
  const { token } = useParams();
  const { pageSpinners } = useSelector(state => state.spinners);

  useEffect(() => {
    if (!token && storageToken) {
      navigate(`/${storageToken}`)
    }
    if (token !== storageToken) {
      dispatch(setUserData(null));
      dispatch(setUserToken(""));
      dispatch(setIsShowAuthModal(false));
    }
  }, [token, storageToken])

  const [modalContent, setModalContent] = useState({
    show: false,
    title: "",
    children: null,
  });


  return (
    // Context - for footer modals
    <ModalContext.Provider value={{
      modalContent,
      setModalContent
    }}>
      {/* <Header /> */}
      <Outlet />
      <ToastContainer theme="dark" />
      <PageSpinner spinner={pageSpinners} />
      {
        !storageToken ?
          <div className="animation-logo">
            <LogoAnimation />
          </div>
          : null
      }
    </ModalContext.Provider>
  );
}

export default Layaut;