import { combineReducers, configureStore } from "@reduxjs/toolkit";
import mainReducer from "./Reducers/mainReducer"
import spinnersReducer from "./Reducers/spinnersReducer"
import userReducer from "./Reducers/userReducer"
import errorReducer from "./Reducers/errorReducer"

const rootReducer = combineReducers({
  spinners: spinnersReducer,
  main: mainReducer,
  user: userReducer,
  error: errorReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
