import React, { memo } from "react"
import AlertService from "../../../../Services/AlertService";
import ApiService from "../../../../Services/ApiService";
import { Button } from "react-bootstrap";

const MainPrice = memo(({ paymentData, tick, price, organizationData, selectedChain, selectedToken, }) => {

    const cancelPayment = () => {
        AlertService.alertConfirm(
            `Payment cancellation`,
            "Are you sure you want to cancel this payment?",
            "Yes",
            "No"
        ).then(() => {
            ApiService.cancelPayment(localStorage.getItem("token")).then(() => {
                window.location.href = localStorage.getItem("cancelUrl")
            }).catch(error => error && AlertService.alert("error", error));
        }).catch(() => { });
    };

    return <div className="main-information">
        <div className="main-information-item">
            <div className="mb-3 title-block">
                <strong >Main Information</strong>
            </div>
            <div className="cx-window-body-container">

                <div className="info-block">
                    <div className="text-muted text-nowrap">Order</div>
                    <div className="dotes w-100" />
                    {
                        paymentData && paymentData.remoteTransactionId ?
                            <div className="text-muted">{paymentData.remoteTransactionId}</div>
                            : <div className="text-muted text-nowrap">Not set</div>
                    }
                </div>
                <div className="info-block mt-2">
                    <div className="text-muted text-nowrap">Network</div>
                    <div className="dotes w-100" />
                    <div className="text-muted text-nowrap">
                        {
                            selectedChain ?
                                <div className="d-flex gap-2 align-items-center">
                                    <img src={selectedChain.currencyLogo} width={20} className="rounded" />
                                    <span>{selectedChain.chainName}</span>
                                </div>
                                : "Not set"
                        }
                    </div>
                </div>
                <div className="info-block mt-2">
                    <div className="text-muted text-nowrap">Token</div>
                    <div className="dotes w-100" />
                    <div className="text-muted text-nowrap">
                        {
                            selectedToken ?
                                <div className="d-flex gap-2 align-items-center">
                                    <img src={selectedToken.logo} width={20} className="rounded" />
                                    <span>{selectedToken?.slug}</span>
                                </div>
                                : "Not set"
                        }
                    </div>
                </div>
                <div className="info-block mt-2">
                    <div className="text-muted text-nowrap">Total price</div>
                    <div className="dotes w-100" />
                    <div className="text-muted text-nowrap">
                        {paymentData && paymentData.fiatAmount ? `$ ${paymentData.fiatAmount}` : ""}
                    </div>
                </div>
                <div className="info-block mt-2">
                    <div className="text-muted text-nowrap">Exchange rate</div>
                    <div className="dotes w-100" />
                    <div className="text-muted text-nowrap">
                        {
                            tick ?
                                <span id="currenttick">$ {tick.toFixed(2)}</span>
                                : "Not set"
                        }
                    </div>
                </div>
                <div className="info-block mt-2">
                    <div className="text-muted text-nowrap">Total</div>
                    <div className="dotes w-100" />
                    <div className="text-muted text-nowrap">
                        {
                            price ?
                                <div className="d-flex align-items-center gap-2">
                                    {
                                        price ?
                                            <span>$ {price}</span>
                                            : null
                                    }
                                </div>
                                : "Not set"
                        }
                    </div>
                </div>
                <div className="mt-4 title-block">
                    <strong >Transaction Text</strong>
                    <p className="mt-2">
                        {paymentData && paymentData.transactionText ? paymentData.transactionText : ""}
                    </p>
                </div>

                <div className="mt-4 title-block">
                    <strong >Payment information</strong>
                </div>
                <div className="cx-orders-container flex-column d-flex gap-2 overflow-auto mt-3">
                    {
                        paymentData && paymentData.paymentProductInfo && paymentData.paymentProductInfo.length ?
                            paymentData.paymentProductInfo.map((el, index) => {
                                return <div key={index} className="cx-order-wrapper">
                                    <img src={el.imageUrl} alt={el.name} />
                                    {
                                        el.description ?
                                            <p className="cx-order-desction">{el.description}</p>
                                            : null
                                    }
                                    <div className="cx-order-price">
                                        {
                                            el.cost ?
                                                <strong>
                                                    <span>$</span>{el.cost}
                                                </strong>
                                                : null
                                        }
                                    </div>
                                </div>
                            })
                            : null
                    }
                </div>


                {/* {
                <div className="cx-company-name-wrapper">
                    {
                        organizationData && organizationData.logoImage && organizationData.logoImage ?
                            <img src={organizationData.logoImage} alt="Organization logo" />
                            : null
                    }
                    {
                        organizationData && organizationData.organizationName && organizationData.organizationName ?
                            <p style={{ marginLeft: organizationData.logoImage ? "20px" : 0 }}>{organizationData.organizationName}</p>
                            : null
                    }
                </div>
            } */}


            </div>
        </div>
        <div className="cancel-payment-block">
            <Button
                size="sm"
                variant="danger"
                className="px-3"
                onClick={cancelPayment}
            >
                Cancel Payment
            </Button>
        </div>
    </div>
})



export default MainPrice