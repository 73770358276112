// import React, { memo } from 'react';
// import MainService from '../../../../Services/MainService';

// const PayBtn = memo((props) => {

//     const { price, paymentData, selectedCrypto, isTransactionValidated, pay, isSupportedCrypto, isConnected } = props;
//     return paymentData ? <div className="m-5">
//         <div
//             id="pay"
//             className={`cx-pay-container ${isTransactionValidated && selectedCrypto && isSupportedCrypto && isConnected ? "cx-pay-container-active" : ""}`}
//             // className={`cx-pay-container ${isTransactionValidated && isSupportedCrypto && isConnected ? "cx-pay-container-active" : ""}`}
//             onClick={() => {
//                 if (isTransactionValidated && selectedCrypto && isSupportedCrypto && isConnected) {
//                     // if (isTransactionValidated && isSupportedCrypto && isConnected) {
//                     pay();
//                 }
//             }}>
//             <div className="cx-pay-wrapper">
//                 <img src="/assets/icons/crillex.svg" alt="" />
//                 <strong>Pay</strong>
//             </div>
//             <div className="cx-total-wrapper">
//                 <p><span className="inf_fiatPrice"><b>{paymentData.fiatAmount ? `$ ${paymentData.fiatAmount}` : ""}</b></span></p>
//                 <span className="cx-order-list-item-value">
//                     {
//                         selectedCrypto ?
//                             <div className='small-icon'>
//                                 {MainService.getCoinImage(selectedCrypto?.name)}
//                             </div>
//                             : null
//                     }
//                     {
//                         price ?
//                             <span className="totalcost ms-2"><b>{price}</b></span>
//                             : null
//                     }

//                 </span>
//             </div>
//         </div>
//     </div> : null
// })

// export default PayBtn;



import React, { memo } from 'react';
import { useAccount, useWriteContract } from 'wagmi';
import { parseUnits } from 'viem';
import cryllexSvg from "./../../../../assets/images/crillex.svg"
import { Button } from 'react-bootstrap';

const WalletConnectPayBtn = memo(({
    price,
    paymentData,
    isTransactionValidated,
    pay,
    selectedToken,
    paySuccess,
    payError,
    selectedChain,
    isLoadingPay,
}) => {

    const { writeContractAsync, isSuccess } = useWriteContract();
    const { isConnected, address } = useAccount();

    const sendTransaction = async (account, amount) => {
        if (!address || !selectedToken) { return false; }
        const parsedAmount = parseUnits(amount.toString(), selectedToken.decimals);
        try {
            const txHash = await writeContractAsync({
                chainId: selectedChain.chainId,
                address: selectedToken.contractAddress,
                abi: JSON.parse(selectedToken.abi),
                functionName: 'transfer',
                args: [account, parsedAmount],
            });

            console.log('Transaction hash:', txHash);
            paySuccess(txHash, selectedChain.id);
        } catch (error) {
            payError(error.name, error.message)
        }
    };

    return paymentData ? <div className='h-100 d-flex justify-content-center align-items-center'>
        <Button
            type='button'
            variant='primary'
            size='lg'
            className='w-100'
            style={{ maxWidth: "400px", fontWeight: "500" }}
            disabled={isLoadingPay || !isTransactionValidated || !isConnected ? true : false}
            onClick={() => {
                if (isTransactionValidated && isConnected && !isLoadingPay) {
                    pay(sendTransaction);
                }
            }}
        >
            <div className='d-flex justify-content-between'>
                <div className='d-flex align-items-center gap-2'>
                    <img src={cryllexSvg} alt="/" />
                    {
                        isLoadingPay ? "Loading..." : "Pay"
                    }

                </div>
                <div>
                    {
                        price ?
                            <span className="ms-2"><b>{price} {selectedToken?.slug}</b></span>
                            : null
                    }
                </div>
            </div>
        </Button>
    </div> : null
})

export default WalletConnectPayBtn;

