import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import WalletConnectComponent from './../WalletConnectComponent';
import uuid from 'react-uuid';
import * as signalR from '@microsoft/signalr'
import ApiService from './../Services/ApiService';
import AlertService from './../Services/AlertService';
import { arbitrum, avalanche, base, bsc, mainnet, optimism, polygon } from "wagmi/chains"
import Main from './../modules/main/_Main';
import { AVALANCHE_PLATFORM_TYPE, ETHEREUM_PLATFORM_TYPE, SOLANA_PLATFORM_TYPE, TRON_PLATFORM_TYPE } from './../Constants/MainKeys';
import { addPageSpinner, removePageSpinner } from './../Store/Reducers/spinnersReducer';
import { setToken } from './../Store/Reducers/mainReducer';
import SolanaComponent from './../SolanaComponent';
import TronComponent from './../TronComponent';
import { useParams } from 'react-router-dom';
import AuthModal from '../Components/Auth/AuthModal';
import TransactionExpiredModal from '../Components/TransactionExpired/TransactionExpiredModal';
import Footer from '../modules/Footer';

export default function Home() {

  const { token } = useParams();

  const API_URL_KEY = process.env.REACT_APP_API_URL_KEY || "";
  const dispatch = useDispatch();
  const { user, userToken, refreshToken } = useSelector(state => state.user);
  const { cancelUrl } = useSelector(state => state.main);
  const [globalConnection, setGlobalConnection] = useState(null);
  const [cryllexChains, setCryllexChains] = useState([]);
  const [walletConnectChains, setWalletConnectChains] = useState([]);

  const [selectedChain, setSelectedChain] = useState(null);
  const [selectedToken, setSelectedToken] = useState(null);

  const [countries, setCountries] = useState([]);
  const [isShowNetworkLoading, setIsShowNetworkLoading] = useState(false);
  const [isShowTokenLoading, setIsShowTokenLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);

  const wagmiChanis = [arbitrum, avalanche, base, bsc, mainnet, optimism, polygon];

  // useEffect(() => {
  //   if (user) {
  //     getCountries();
  //     getChains();
  //   }
  //   return () => {
  //     setCountries([]);
  //     setCryllexChains([]);
  //   }
  // }, [user])

  useEffect(() => {
    getCountries();
    getChains();
    return () => {
      setCountries([]);
      setCryllexChains([]);
    }
  }, [])

  const getCountries = () => {
    ApiService.getCountries().then(response => {
      if (response && response.data) {
        setCountries(response.data);
      }
    }).catch(error => AlertService.alert("error", error));
  }


  useEffect(() => {
    if (cryllexChains && cryllexChains.length && wagmiChanis && wagmiChanis.length) {
      let _chains = [];
      cryllexChains.forEach(item => {
        wagmiChanis.forEach(_item => {
          if (item.chainId === _item.id) {
            _item.config = item;
            _chains.push(_item);
          }
        })
      })
      setWalletConnectChains(_chains);
    }

  }, [cryllexChains])

  const getChains = () => {
    const spinnerId = uuid();
    dispatch(addPageSpinner(spinnerId));
    ApiService.getChains().then(response => {
      if (response && response.data && response.data.data) {
        setCryllexChains(response.data.data)
      }
    }).catch(error => AlertService.alert("error", error)).finally(() => {
      dispatch(removePageSpinner(spinnerId));
    })
  }

  // useEffect(() => {

  //   localStorage.clear();
  //   indexedDB.deleteDatabase("WALLET_CONNECT_V2_INDEXED_DB");
  //   debugger
  //   if (cancelUrl) {
  //     localStorage.setItem("cancelUrl", cancelUrl);
  //   }
  //   if (user) {
  //     localStorage.setItem("user", JSON.stringify(user));
  //   }
  //   if (userToken) {
  //     localStorage.setItem("userToken", userToken);
  //   }
  //   if (refreshToken) {
  //     localStorage.setItem("refreshToken", refreshToken);
  //   }

  //   if (token) {
  //     dispatch(setToken(token));
  //     signal_start();
  //   }
  //   return () => {
  //     localStorage.clear();
  //     indexedDB.deleteDatabase("WALLET_CONNECT_V2_INDEXED_DB");
  //     debugger
  //     if (cancelUrl) {
  //       localStorage.setItem("cancelUrl", cancelUrl);
  //     }
  //     if (user) {
  //       localStorage.setItem("user", JSON.stringify(user));
  //     }
  //     if (userToken) {
  //       localStorage.setItem("userToken", userToken);
  //     }
  //     if (refreshToken) {
  //       localStorage.setItem("refreshToken", refreshToken);
  //     }
  //     sessionStorage.clear();
  //     deleteAllCookies();
  //   }
  // }, [user]);

  useEffect(() => {
    localStorage.removeItem("wagmi.store");
    localStorage.removeItem("wagmi.recentConnectorId");
    localStorage.removeItem("@w3m/connected_connector");
    indexedDB.deleteDatabase("WALLET_CONNECT_V2_INDEXED_DB");
    sessionStorage.clear();
    deleteAllCookies();

    if (token) {
      dispatch(setToken(token));
      signal_start();
    }
  }, [])

  const deleteAllCookies = () => {
    document.cookie.split(';').forEach(cookie => {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    });
  }

  const signal_start = async () => {
    // if (!user) { return false; }
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${API_URL_KEY}/paymenthub`)
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.None)
      .build();
    connection.serverTimeoutInMilliseconds = 1000 * 60 * 10;
    try {
      await connection.start();
      await signal_init(connection);
      await signal_update(connection);
      console.assert(connection.state === signalR.HubConnectionState.Connected);
      console.log("SignalR Connected.");
      setGlobalConnection(connection);
      connection.onclose(() => {
        setTimeout(() => signal_start(), 1000);
      });
    } catch (err) {
      console.assert(connection.state === signalR.HubConnectionState.Disconnected);
      setTimeout(() => signal_start(), 1000);
    }
  };

  const signal_init = async (connection) => {
    try {
      await connection.invoke("init", token);
    } catch (err) {
      setTimeout(() => { signal_init(); }, 1000);
    }
  }

  const signal_update = async (connection) => {
    try {
      await connection.invoke("update", token);
    } catch (err) {
      setTimeout(() => { signal_update(); }, 1000);
    }
  }

  const setMainContent = (chains = []) => {
    // if (!user) { return false; }
    return <Main
      chains={chains}
      connection={globalConnection}
      signal_update={signal_update}
      selectedToken={selectedToken}
      selectedChain={selectedChain}
      onSetNetwork={onSetNetwork}
      onSetCrypto={onSetCrypto}
      countries={countries}
      isShowNetworkLoading={isShowNetworkLoading}
      isShowTokenLoading={isShowTokenLoading}
      setSelectedChain={setSelectedChain}
      setSelectedToken={setSelectedToken}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    />
  }

  const onSetNetwork = async (chain) => {
    if (!chain) { return false; }
    setIsShowNetworkLoading(true);
    // setSelectedToken(null);
    ApiService.onSetNetwork({ networkId: chain?.id }).then(() => {
      setSelectedChain(chain);
      onSetCrypto(chain.tokens[0] || null);
    }).catch(error => AlertService.alert("error", error)).finally(() => {
      setIsShowNetworkLoading(false);
    })
    // setSelectedChain(await switchChain(config, { chainId: chain.id }))
  }

  const onSetCrypto = async (_token) => {
    if (!_token) { return false; }
    setIsShowTokenLoading(true);
    ApiService.onSetCrypto({ cryptoCurrencyId: _token?.id, name: _token?.slug }).then(() => {
      setSelectedToken(_token);
      setActiveTab(3)
    }).catch(error => AlertService.alert("error", error)).finally(() => {
      setIsShowTokenLoading(false);
    })
  }

  return (
    <div>
      {/* <AuthModal /> */}
      <TransactionExpiredModal />
      {
        cryllexChains && cryllexChains.length && walletConnectChains && walletConnectChains.length ?
          <div>
            {(() => {
              switch (selectedChain?.platformType) {
                case AVALANCHE_PLATFORM_TYPE:
                  return (
                    <WalletConnectComponent chains={walletConnectChains}>
                      {setMainContent(cryllexChains)}
                    </WalletConnectComponent>
                  );
                case ETHEREUM_PLATFORM_TYPE:
                  return (
                    <WalletConnectComponent chains={walletConnectChains}>
                      {setMainContent(cryllexChains)}
                    </WalletConnectComponent>
                  );
                case SOLANA_PLATFORM_TYPE:
                  return (
                    <SolanaComponent chains={walletConnectChains}>
                      {setMainContent(cryllexChains)}
                    </SolanaComponent>
                  );
                case TRON_PLATFORM_TYPE:
                  return (
                    <TronComponent chains={walletConnectChains}>
                      {setMainContent(cryllexChains)}
                    </TronComponent>
                  );
                default:
                  return (
                    <WalletConnectComponent chains={walletConnectChains}>
                      {setMainContent(cryllexChains)}
                    </WalletConnectComponent>
                  );
              }
            })()}
          </div>
          : null
      }
      <Footer chains={cryllexChains} />
    </div>
  )
}
